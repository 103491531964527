import { useQuery } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { getBooks } from "./actions";
import { booksKeys } from "./constants";

const useBooks = () => {
  const query = useQuery({
    queryKey: booksKeys.books, // используем queryKey вместо первого аргумента
    queryFn: getBooks, // используем queryFn вместо второго аргумента
    onError: (error) => {
      if (isAxiosError(error) && error.response?.status === 403) {
        return;
      }
    },
  });

  return query;
};

export default useBooks;
