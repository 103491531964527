import httpClient from "../../httpClient";
import queryClient from "../../queryClient";
import { booksKeys } from "./constants";

export const getBooks = async () => {
  const { data } = await httpClient.get(`/test/bookings/books`);

  return data;
};

export const setBookForHourRequest = async (bookInfo) => {
  try {
    await httpClient.post(`/test/bookings/book`, bookInfo);
    queryClient.invalidateQueries(booksKeys.books);
    alert(
      `Слот на ${
        (new Date(bookInfo.date).toLocaleDateString("ru-RU", {
          day: "numeric",
          month: "long",
        }),
        bookInfo.time)
      } успешно забронирован.`
    );
  } catch (err) {
    if (err.message.includes(430)) alert("Слот уже забронирован.");
  }
};
