import React, { useEffect, useState } from "react";
import { useBooks } from "../api";
import { setBookForHourRequest } from "../api/resources/booking/actions";

// Функция для генерации времён с текущего часа до 24:00
const generateTimes = (selectedDay) => {
  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    times.push(`${hour.toString().padStart(2, "0")}:00`);
  }
  return times;
};

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const BookingForm = () => {
  const [selectedDay, setSelectedDay] = useState("today");
  const [selectedTime, setSelectedTime] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    setSelectedTime("");
  }, [selectedDay]);

  const times = generateTimes(selectedDay);

  const booksQuery = useBooks();

  if (!booksQuery.isSuccess) {
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedTime || !name) {
      alert("Пожалуйста, заполните все поля!");
      return;
    }

    const newDate = new Date();
    if (selectedDay === "today") {
      newDate.setDate(newDate.getDate()); // Оставляем сегодняшнюю дату
    } else if (selectedDay === "tomorrow") {
      newDate.setDate(newDate.getDate() + 1); // Устанавливаем завтрашнюю дату
    }

    await setBookForHourRequest({
      date: formatDate(newDate), // Используем выбранную дату
      time: selectedTime,
      userName: name,
    });

    setSelectedTime("");
    setName("");
  };

  return (
    <form style={styles.form} onSubmit={handleSubmit}>
      <h2 style={styles.title}>Бронирование</h2>

      {/* Выбор дня */}
      <label style={styles.label}>
        День:
        <select
          value={selectedDay}
          onChange={(e) => setSelectedDay(e.target.value)}
          style={styles.select}
        >
          <option value="today">Сегодня</option>
          <option value="tomorrow">Завтра</option>
        </select>
      </label>

      {/* Выбор времени */}
      <label style={styles.label}>
        Время:
        <select
          value={selectedTime}
          onChange={(e) => setSelectedTime(e.target.value)}
          style={styles.select}
        >
          <option value="" disabled>
            Выберите время
          </option>
          {times.map((time, index) => {
            const now = new Date();
            const currentHour =
              selectedDay === "today" ? now.getHours() + 1 : 0;
            return (
              !booksQuery.data.bookings[selectedDay].slots[index].bookedBy &&
              index >= currentHour && (
                <option key={time} value={time}>
                  {time}
                </option>
              )
            );
          })}
        </select>
      </label>

      {/* Ввод имени */}
      <label style={styles.label}>
        Имя:
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Введите ваше имя"
          style={styles.input}
        />
      </label>

      {/* Кнопка бронирования */}
      <button type="submit" style={styles.button}>
        Забронировать
      </button>
    </form>
  );
};

const styles = {
  form: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    maxWidth: "400px",
    margin: "0 auto",
    padding: "16px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
  },
  title: {
    textAlign: "center",
    marginBottom: "16px",
  },
  label: {
    marginBottom: "12px",
    fontWeight: "bold",
  },
  select: {
    width: "100%",
    padding: "8px",
    marginTop: "4px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  input: {
    width: "95%",
    padding: "8px",
    marginTop: "4px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  button: {
    marginTop: "16px",
    padding: "10px",
    border: "none",
    borderRadius: "4px",
    backgroundColor: "#007BFF",
    color: "#fff",
    fontSize: "16px",
    cursor: "pointer",
  },
};

export default BookingForm;
