import {
  QueryClientProvider as BaseQueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import httpClient from "./httpClient";

const defaultQueryFunction = async ({ queryKey, signal }) =>
  httpClient.get(queryKey.join("/")).then((resp) => resp.data);

const onError = (error) => {
  if (
    error instanceof Error &&
    error.message.includes("Both secret and user tokens are not set")
  ) {
    return;
  }

  if (axios.isAxiosError(error)) {
    console.error(
      "⚠️ Axios client error:",
      JSON.stringify(error.response?.data, null, 2)
    );
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFunction,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      retry: false,
    },
    mutations: {
      onError,
    },
  },
});

export const QueryClientProvider = ({ children }) => (
  <BaseQueryClientProvider client={queryClient}>
    {children}
  </BaseQueryClientProvider>
);

export default queryClient;
