import React from "react";
import { QueryClientProvider } from "./api/queryClient";
import BookingForm from "./components/BookingForm";
import Canvas from "./components/Canvas/Canvas";
import DayBookingBlock from "./components/DayBookingBlock";
import "./index.css";

function App() {
  return (
    <QueryClientProvider>
      <Canvas />
      <BookingForm />
      <DayBookingBlock />
    </QueryClientProvider>
  );
}

export default App;
