import React from "react";
import { useBooks } from "../api";
import Card from "./Card";
import "./DayBookingBlock.css"; // Подключение CSS

const DayBookingBlock = () => {
  const booksQuery = useBooks();

  if (!booksQuery.isSuccess) {
    return null;
  }

  return (
    <div className="day-booking-block">
      <Card bookings={booksQuery.data.bookings.today} isTomorrow={false} />
      <Card bookings={booksQuery.data.bookings.tomorrow} isTomorrow={true} />
    </div>
  );
};

export default DayBookingBlock;
